import GloIcon from "../../../img/webform-offers/glo.jpg"
import VuseIcon from "../../../img/webform-offers/vuse.jpg"
import VeloIcon from "../../../img/webform-offers/velo.jpg"
import VeoIcon from "../../../img/webform-offers/veo.jpg"
import IcIcon from "../../../img/webform-offers/ic.jpg"

export const availableColors = new Map([
  [
    "Hyper+ UNIQ",
    [
      {
        value: "Black",
        label: "Black",
      },
      {
        value: "Blue",
        label: "Blue",
      },
      {
        value: "White",
        label: "White",
      },
      {
        value: "Gold",
        label: "Gold",
      },
    ],
  ],

  [
    "Hyper X2",
    [
      {
        value: "White-Gold",
        label: "Biało-Złoty",
      },
      {
        value: "Green-Pine",
        label: "Zielono-Miętowy",
      },
      {
        value: "Black-Red",
        label: "Czarno-Czerwony",
      },
      {
        value: "Black",
        label: "Czarny",
      },
      {
        value: "Blue",
        label: "Niebieski",
      },
      {
        value: "Orange",
        label: "Pomarańczowy",
      },
      {
        value: "khaki",
        label: "Khaki",
      },
    ],
  ],

  [
    "ePen",
    [
      {
        value: "Black",
        label: "Black",
      },
      {
        value: "Grey",
        label: "Grey",
      },
      {
        value: "Rose",
        label: "Rose",
      },
      {
        value: "Gold",
        label: "Gold",
      },
      {
        value: "Silver",
        label: "Silver",
      },
      {
        value: "Champagne",
        label: "Champagne",
      },
      {
        value: "Blue",
        label: "Blue",
      },
      {
        value: "Pink",
        label: "Pink",
      },
      {
        value: "Graphite",
        label: "Graphite",
      },
    ],
  ],

  [
    "Hyper X2 air",
    [
      {
        value: "Moonless Black",
        label: "Moonless Black",
      },
      {
        value: "Aurora Teal",
        label: "Light Teal",
      },
      {
        value: "Crisp Purple",
        label: "Crisp Purple",
      },
      {
        value: "Rose Gold",
        label: "Rosey Gold",
      },
      {
        value: "Celestial Navy",
        label: "Space Navy",
      },
      {
        value: "Velvet Pink",
        label: "Velvet Pink",
      },
      {
        value: "Ocean Blue",
        label: "Ocean Blue",
      },
    ],
  ],

  [
    "Hyper Pro",
    [
      {
        value: "Ruby Black",
        label: "Ruby Black",
      },
      {
        value: "Obsidian Black",
        label: "Obsidian Black",
      },
      {
        value: "Lapis Blue",
        label: "Lapis Blue",
      },
      {
        value: "Jade Teal",
        label: "Jade Teal",
      },
      {
        value: "Purple Sapphire",
        label: "Purple Sapphire",
      },
      {
        value: "Quartz Rose",
        label: "Quartz Rose",
      },
      {
        value: "Amber Bronze",
        label: "Amber Bronze",
      },
    ],
  ],

  [
    "ePod 2",
    [
      {
        value: "Black",
        label: "Black",
      },
      {
        value: "Grey",
        label: "Grey",
      },
      {
        value: "Rose",
        label: "Rose",
      },
      {
        value: "Gold",
        label: "Gold",
      },
      {
        value: "Silver",
        label: "Silver",
      },
      {
        value: "Champagne",
        label: "Champagne",
      },
      {
        value: "Blue",
        label: "Blue",
      },
      {
        value: "Pink",
        label: "Pink",
      },
      {
        value: "Graphite",
        label: "Graphite",
      },
    ],
  ],
  [
    "Vuse Go",
    [
      {
        value: "Vuse GO Reload 1000 Berry Blend",
        label: "Vuse GO Reload 1000 Berry Blend",
      },
      {
        value: "Vuse GO Reload 1000 Grape Ice",
        label: "Vuse GO Reload 1000 Grape Ice",
      },
      {
        value: "Vuse GO Reload 1000 Peppermint Ice",
        label: "Vuse GO Reload 1000 Peppermint Ice",
      },
      {
        value: "Vuse Go Reload 1000 Blueberry Ice",
        label: "Vuse Go Reload 1000 Blueberry Ice",
      },
      {
        value: "Vuse Go Reload 1000 Mango Ice",
        label: "Vuse Go Reload 1000 Mango Ice",
      },
      // {
      //   value: "Apple Sour",
      //   label: " Apple Sour",
      // },
      // {
      //   value: "Blueberry Ice",
      //   label: "Blueberry Ice",
      // },
      // {
      //   value: "Peppermint Ice",
      //   label: "Peppermint Ice",
      // },
      // {
      //   value: "Mango Ice",
      //   label: "Mango Ice",
      // },
      // {
      //   value: "Strawberry Ice",
      //   label: "Strawberry Ice",
      // },
      // {
      //   value: "Berry Watermelon",
      //   label: "Berry Watermelon",
      // },
      // {
      //   value: "Watermelon Ice",
      //   label: "Watermelon Ice",
      // },
      // {
      //   value: "Berry Blend",
      //   label: "Berry Blend",
      // },
      // {
      //   value: "Grape Ice",
      //   label: "Grape Ice",
      // },
      // {
      //   value: "Creamy Tobacco",
      //   label: "Creamy Tobacco",
      // },
    ],
  ],
]);

// default values only for schema validation
export const availableDevices = (
  availableOptions = { Vuse: true, Glo: true, Velo: true, Neo: true }
) => {
  return [
    ...(availableOptions.Vuse
      ? [
        {
          label: "Vuse",
          value: "Vuse",
        },
      ]
      : []),

    ...(availableOptions.Glo
      ? [
        {
          label: "Glo",
          value: "Glo",
        },
      ]
      : []),
  ];
};

// default values only for schema validation
export const registerType = (
  availableOptions = { Vuse: true, Glo: true, Velo: true, Neo: true }
) => {
  return [
    ...(availableOptions.Vuse || availableOptions.Glo
      ? [
        {
          label: "Rejestracja urządzenia",
          value: "device-register",
        },
      ]
      : []),

    ...((availableOptions.Vuse || availableOptions.Glo) && availableOptions.Velo
      ? [
        {
          label: "Rejestracja urządzenia + Sampling",
          value: "device-register-sampling",
        },
      ]
      : []),

    // ...(availableOptions.Vuse && availableOptions.Glo
    //   ? [
    //       {
    //         label: "Rejestracja zestawu urządzeń",
    //         value: "bundle-register",
    //       },
    //     ]
    //   : []),

    ...(availableOptions.Velo
      ? [
        {
          label: "Wydanie Samplingu Velo",
          value: "sampling",
        },
      ]
      : []),

    ...(availableOptions.Neo
      ? [
        {
          label: "Wydanie ofery neo™",
          value: "neo-offer",
        },
      ]
      : []),

    // {
    //   label: "Rejestracja konsumenta",
    //   value: "user-register"
    // },
  ];
};

export const registerLocation = (
  availableOptions = { Vuse: true, Glo: true, Velo: true, Neo: true }
) => {
  return [
    // ...(availableOptions.Vuse || availableOptions.Glo) ? [{
    //   label: "Rejestracja urządzenia",
    //   value: "device-register"
    // }] : [],

    ...((availableOptions.Vuse || availableOptions.Glo) && availableOptions.Velo
      ? [
        {
          label: "Rejestracja urządzenia + Sampling",
          value: "device-register-sampling",
        },
      ]
      : []),

    // ...(availableOptions.Vuse && availableOptions.Glo) ? [{
    //   label: "Rejestracja zestawu urządzeń",
    //   value: "bundle-register"
    // }] : [],

    ...(availableOptions.Velo
      ? [
        {
          label: "Wydanie Samplingu Velo",
          value: "sampling",
        },
      ]
      : []),

    ...(availableOptions.Neo
      ? [
        {
          label: "Wydanie ofery neo™",
          value: "neo-offer",
        },
      ]
      : []),

    // {
    //   label: "Rejestracja konsumenta",
    //   value: "user-register"
    // },
  ];
};

export const veloSampling = [
  {
    value: "Velo Icy Berries Mellow 6mg",
    label: "Velo Icy Berries Mellow 6mg",
    sku: "K92.103.0045",
  },
  {
    value: "Velo Spiffy Spearmint Mellow 6mg",
    label: "Velo Spiffy Spearmint Mellow 6mg",
    sku: "K92.103.0031",
  },
  {
    value: "Velo Blushy Berry Mellow 6mg",
    label: "Velo Blushy Berry Mellow 6mg",
    sku: "K92.103.0028",
  },
  {
    value: "Velo Breezy Mango Original 7.9mg",
    label: "Velo Breezy Mango Original 7.9mg",
    sku: "K92.103.0046",
  }
];

export const numberPrefixes = [
  {
    label: "+48",
    value: "+48",
  },
  {
    label: "+380",
    value: "+380",
  },
  {
    label: "+44",
    value: "+44",
  },
  {
    label: "+49",
    value: "+49",
  },
  {
    label: "+420",
    value: "+420",
  },
  {
    label: "+370",
    value: "+370",
  },
  {
    label: "+371",
    value: "+371",
  },
  {
    label: "+372",
    value: "+372",
  },
  {
    label: "+421",
    value: "+421",
  },
];

export const categories = [
  {
    value: "Glo",
    label: "GLO",
  },
  {
    value: "iQOS",
    label: "IQOS",
  },
  {
    value: "E-Cigarettes",
    label: "E-Papierosy",
  },
  {
    value: "Traditional cigarettes",
    label: "Tradycyjne Papierosy",
  },
  {
    value: "EPOK",
    label: "VELO/EPOK",
  },
  {
    value: "Other",
    label: "Inne",
  },
];

export const cigs = [
  {
    value: "ROTHMANS",
    label: "ROTHMANS",
  },
  {
    value: "PALL MALL",
    label: "PALL MALL",
  },
  {
    value: "MARLBORO",
    label: "MARLBORO",
  },
  {
    value: "L&M",
    label: "L&M",
  },
  {
    value: "CHESTERFIELD",
    label: "CHESTERFIELD",
  },
  {
    value: "WINSTON",
    label: "WINSTON",
  },
  {
    value: "CAMEL",
    label: "CAMEL",
  },
  {
    value: "DAVIDOFF",
    label: "DAVIDOFF",
  },
  {
    value: "LD",
    label: "LD",
  },
  {
    value: "R1",
    label: "R1",
  },
  {
    value: "RGD",
    label: "RGD",
  },
  {
    value: "VOGUE",
    label: "VOGUE",
  },
  {
    value: "WEST",
    label: "WEST",
  },
  {
    value: "Other",
    label: "Inne",
  },
];

export const ecigs = [
  {
    value: "Logic",
    label: "Logic",
  },
  {
    value: "Juul",
    label: "Juul",
  },
  {
    value: "Open system",
    label: "System Otwarty",
  },
  {
    value: "Vuse",
    label: "Vuse",
  },
  {
    value: "Vype",
    label: "Vype",
  },
  {
    value: "Other",
    label: "Inne",
  },
];

export const assetSources = new Map([
  ["Glo", [
    {
      label: "GLO i PACZKI POP",
      value: "HST – oferta POP",
      sku: "VEOSAMP1",
    },
    {
      label: "4 PACZKI POP + GLO kasoterminal",
      value: "HST – sprzedaż HST",
      sku: "VEOOFFER6",
    }
  ]],
  ["Glo_HUTD", [
    {
      label: "GLO solo / POP",
      value: "HST – oferta POP",
      sku: "GLOPRO1",
    },
    {
      label: "GLO solo / KASOTERMINAL",
      value: "HST – sprzedaż HST",
      sku: "GLOPRO2",
    },
    {
      label: "Paczki POP + GLO KASOTERMINAL",
      value: "HST – sprzedaż HST",
      sku: "GLOPROPAC1",
    },
    {
      label: "Paczki POP + GLO KASOTERMINAL  zg. Markt.",
      value: "HST – sprzedaż HST",
      sku: "GLOPROPAC2",
      requireAllConsents: true
    },
    {
      label: "GLO + 2 Paczki / POP zg. Markt.",
      value: "HST – oferta POP",
      sku: "GLOPROPAC3",
      requireAllConsents: true
    },
    {
      label: "GLO + 2 Paczki / KASOTERMINAL zg. Markt.",
      value: "HST – sprzedaż HST",
      sku: "GLOPROPAC4",
      requireAllConsents: true
    },
  ]],
  ["Vuse", [
    {
      label: "Wkład kasoterminal + VUSE BM",
      value: "HST – sprzedaż HST",
      sku: "VUSEBM1024",
      requireAllConsents: true
    },
    {
      label: "Wkład POP + VUSE BM",
      value: "HST – oferta POP",
      sku: "VUSEPOP1024",
      requireAllConsents: true
    },
  ]]
])

export const neoOffers = [
  {
    value: "0",
    label: "2 NEO/VEO POP + 1 VEO BM",
    sku: "CFVEOSAMP1",
  },
  {
    value: "1",
    label: "2 NEO/VEO i 1 VEO BM",
    sku: "CFVEOSAMP2",
  },
];

export const model = new Map([
  [
    "Vuse",
    [
      {
        label: "Vuse Go",
        value: "Vuse Go",
      },
    ],
  ],

  [
    "Glo",
    [
      {
        label: "Hyper PRO",
        value: "Hyper Pro",
      },
    ],
  ],
]);

export const currentStepCopy = [
  { title: "Dane konsumenta", desc: "Wpisz <strong>adres e-mail</strong> oraz <strong>numer telefonu</strong>, aby zweryfikować konsumenta i sprawdzić dostępne oferty i promocje." },
  { title: "Weryfikacja", desc: "Wprowadź otrzymane <strong>kody e-mail oraz sms</strong> i&nbsp;zweryfikuj dane." },
  { title: "Dostępne oferty", desc: "<strong>Wybierz oferty i promocje</strong>, którymi zainteresowany jest konsument." },
  { title: "Rejestracja", desc: "<strong>Zarejestruj</strong> konsumenta." },
  { title: "Potwierdzenie", desc: "Gratulacje! <strong>Rejestracja zakończona sukcesem.</strong>" }
]

export const samplingPacks = [
  {
    label: "VEO Arctic Blue Click",
    value: "VEO Arctic Blue Click"
  },
  {
    label: "VEO Green Click",
    value: "VEO Green Click"
  },
  {
    label: "VEO Scarlet Click",
    value: "VEO Scarlet Click"
  },
  {
    label: "VEO Summer Twist",
    value: "VEO Summer Twist"
  },
  {
    label: "VEO Sunset Click",
    value: "VEO Sunset Click"
  },
  {
    label: "VEO Tropical Twist",
    value: "VEO Tropical Twist"
  },
  {
    label: "VEO Violet Click",
    value: "VEO Violet Click"
  },
  {
    label: "Neo Classic Tobacco",
    value: "Neo Classic Tobacco"
  },
  {
    label: "Neo Copper Tobacco",
    value: "Neo Copper Tobacco"
  },
  {
    label: "Neo Gold Tobacco",
    value: "Neo Gold Tobacco"
  },
  {
    label: "Neo Terracotta Tobacco",
    value: "Neo Terracotta Tobacco"
  }
]

export const offersDetails = [
  // {
  //   offer: "Glo",
  //   thumbnail: GloIcon,
  //   title: "Oferta glo™ KA",
  //   desc: 'Zakup przez Konsumenta oferty glo.<br/>W przypadku dodania do glo puszki velo, należy zaznaczyć dodatkowo pole "VELO".',
  //   successText: "Wydaj konsumentowi ofertę glo™.",
  //   availableOptions: {
  //     model: model.get("Glo"),
  //     color: availableColors,
  //     serialNumber: true,
  //     assetSource: assetSources.get("Glo")
  //   }
  // },
  {
    offer: "Glo_HUTD",
    thumbnail: GloIcon,
    // title: "Oferta glo™ HRC/UNC/TT/DA",
    title: "Oferta glo™",
    desc: 'Zakup przez Konsumenta oferty glo.<br/>W przypadku dodania do glo puszki velo, należy zaznaczyć dodatkowo pole "VELO".',
    successText: "Wydaj konsumentowi ofertę glo™.",
    availableOptions: {
      model: model.get("Glo"),
      color: availableColors,
      serialNumber: true,
      assetSource: assetSources.get("Glo_HUTD"),
      samplingOne:samplingPacks,
      samplingTwo:samplingPacks
    }
  },
  {
    offer: "Vuse",
    thumbnail: VuseIcon,
    title: "Oferta Vuse",
    desc: 'Zakup przez Konsumenta vuse+velo',
    successText: "Wydaj konsumentowi ofertę Vuse.",
    availableOptions: {
      model: model.get("Vuse"),
      color: availableColors,
      assetSource: assetSources.get("Vuse")
    }
  },
  {
    offer: "Velo",
    thumbnail: VeloIcon,
    title: "Oferta VELO",
    desc: `
    Wydanie puszki velo do zakupu glo<br/>
    Wydanie puszki velo solo
    `,
    successText: "Wydaj konsumentowi ofertę VELO.",
    availableOptions: {
      sampling: veloSampling,
    }
  },
  {
    offer: "Neo",
    thumbnail: VeoIcon,
    title: "Oferta veo™",
    desc: "Oferta CF<br/>Zakup przez Konsumenta 2 paczek veo/super tobacco – wydanie przez BM 1 veo.",
    successText: "Wydaj konsumentowi ofertę veo™",
    availableOptions: {
      neoOffers: neoOffers
    }
  },
  {
    offer: "InspirationClub",
    thumbnail: IcIcon,
    title: "Inspiration Club",
    desc: "Rejestracja do programu lojalnościowego z ekskluzywnymi ofertami i rabatami.",
    successText: "",
  },
]